(function () {
    const forms = [
        document.querySelector('.js-get-consultation-new-form'),
        document.querySelector('.js-have-questions-new-form'),
        document.querySelector('.js-get-presentation-new-form-popup')
    ];

    const wrapForms = [
        forms[0].querySelector('.js-callback-form-new-wrap-form'),
        forms[1].querySelector('.js-callback-form-new-wrap-form'),
        forms[2].querySelector('.js-callback-form-new-wrap-form')
    ];

    const inputsName = [
        forms[0].querySelector('input[name="name"]'),
        forms[1].querySelector('input[name="name"]'),
        forms[2].querySelector('input[name="name"]')
    ];

    const inputsPhone = [
        forms[0].querySelector('input[name="phone"]'),
        forms[1].querySelector('input[name="phone"]'),
        forms[2].querySelector('input[name="phone"]')
    ];

    const buttons = [
        forms[0].querySelector('.js-callback-form-new-form-button'),
        forms[1].querySelector('.js-callback-form-new-form-button'),
        forms[2].querySelector('.js-callback-form-new-form-button')
    ];

    const successMessages = [
        forms[0].querySelector('.js-callback-form-new-success-message'),
        forms[1].querySelector('.js-callback-form-new-success-message'),
        forms[2].querySelector('.js-callback-form-new-success-message')
    ];

    const errorMessages = [
        forms[0].querySelector('.js-callback-form-new-error-message'),
        forms[1].querySelector('.js-callback-form-new-error-message'),
        forms[2].querySelector('.js-callback-form-new-error-message')
    ];

    inputsPhone.forEach(inputPhone => {
        inputPhone.addEventListener('input', event => {
            const value = event.target.value;

            if (value.length < 3) {
                event.target.value = '+7';
            } else {
                event.target.value = value
                    .replace(/[^+\d]/g, '')
                    .replace(/(?!^\+)\+/g, '')
                    .slice(0, 12);
            }
        });

        inputPhone.addEventListener('focus', event => {
            event.target.value < 3 ? event.target.value = '+7' : null;
            inputPhone.classList.remove('callback-form-new__form-input_error');
        });

        inputPhone.addEventListener('blur', event => {
            const _this = event.target;

            if (_this.value.length === 2) {
                _this.value = '';
                inputPhone.classList.remove('callback-form-new__form-input_error');
            } else if (_this.value.length < 12) {
                inputPhone.classList.add('callback-form-new__form-input_error');
            } else {
                inputPhone.classList.remove('callback-form-new__form-input_error');
            }
        });
    });

    buttons.forEach((button, index) => {
        button.addEventListener('click', () => {
            if (inputsPhone[index].value.length < 12) {
                inputsPhone[index].classList.add('callback-form-new__form-input_error');
            } else {
                inputsPhone[index].classList.remove('callback-form-new__form-input_error');
                button.innerText = 'Отправка...';
                button.disabled = true;

                fetch("https://submit-form.com/forvd00Bc", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify({
                        'Сообщение': 'Новая заявка с сайта kpmarfino.ru',
                        'Имя': inputsName[index].value,
                        'Телефон': inputsPhone[index].value
                    }),
                })
                    .then(response => {
                        button.innerText = 'Получить консультацию';
                        button.disabled = false;

                        if (response.ok) {
                            wrapForms[index].classList.add('callback-form-new_hidden');
                            successMessages[index].classList.remove('callback-form-new_hidden');
                            ym(98652793,'reachGoal','send');
                        } else {
                            wrapForms[index].classList.add('callback-form-new_hidden');
                            errorMessages[index].classList.remove('callback-form-new_hidden');
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        wrapForms[index].classList.add('callback-form-new_hidden');
                        errorMessages[index].classList.remove('callback-form-new_hidden');
                        button.innerText = 'Получить консультацию';
                        button.disabled = false;
                        console.log(error);
                    });
            }
        });
    });
})();
