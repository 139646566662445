(function () {
    const formPopup = document.querySelector('.js-get-presentation-new-form-popup');
    const button = document.querySelector('.js-get-presentation-new-button');

    button.addEventListener('click', () => {
        formPopup.classList.remove('callback-form-new_hidden');
    });

    formPopup.addEventListener('click', event => {
        event.stopPropagation();
        event.preventDefault();

        event.target.classList.contains('js-get-presentation-new-form-popup')
            ? formPopup.classList.add('callback-form-new_hidden')
            : null;
    });
})();
