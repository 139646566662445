(function () {
	const swiper = new Swiper('.location-new__map-swiper', {
		direction: 'horizontal',
		slidesPerView: 1,

		pagination: {
			el: '.location-new__map-swiper-pagination',
		},

		navigation: {
			nextEl: '.location-new__map-swiper-button-next',
			prevEl: '.location-new__map-swiper-button-prev',
		},
	});
})();
